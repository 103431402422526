

import React, { useState, useEffect} from 'react';
import './Premierepage.css';
import Boxe from './composant/Boxe';
import Button from './composant/Button';

import Modal from './composant/Modal';
import Modal1 from './Modalee/Modal1';
import Modal2 from './Modalee/Modal2';
import Modal3 from './Modalee/Modal3';
import Modal4 from './Modalee/Modal4';
import Modal5 from './Modalee/Modal5';
import Modal6 from './Modalee/Modal6';
import Modal7 from './Modalee/Modal7';
import logo1 from './img/Shonen.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";

import SEO from './SEO'; // Assurez-vous que le chemin correspond à l'emplacement réel du fichier
function Premierepage() {
   const [isModalOpen, setIsModalOpen] = useState(false); // État pour gérer la visibilité du Modal
   const [isModal1Open, setIsModal1Open] = useState(false);
   const [isModal2Open, setIsModal2Open] = useState(false);
   const [isModal3Open, setIsModal3Open] = useState(false);
   const [isModal4Open, setIsModal4Open] = useState(false);
   const [isModal5Open, setIsModal5Open] = useState(false);
   const [isModal6Open, setIsModal6Open] = useState(false);
   const [isModal7Open, setIsModal7Open] = useState(false);
   
   // Handle scroll lock/unlock when modals are opened/closed
   useEffect(() => {
    const lockScroll = (modalOpen) => {
      if (modalOpen) {
          document.body.style.overflow = 'hidden';  // Lock scroll
          document.documentElement.style.overflow = 'hidden';  // Ensure the modal scroll is locked
      } else {
          document.body.style.overflow = 'auto';  // Unlock scroll
          document.documentElement.style.overflow = 'auto';  // Unlock modal scroll
      }
  };

  const anyModalOpen = isModalOpen || isModal1Open || isModal2Open || isModal3Open || isModal4Open || isModal5Open  || isModal6Open || isModal7Open ;
  lockScroll(anyModalOpen);

  return () => {
      document.body.style.overflow = 'auto';   // Ensure scroll is unlocked when component unmounts
      document.documentElement.style.overflow = 'auto'; // Unlock when modal is removed
  };
}, [isModalOpen, isModal1Open, isModal2Open, isModal3Open, isModal4Open, isModal5Open,isModal6Open,isModal7Open ]);


    
   const style = {
      color: 'white',
      fontFamily: 'Dreamlands, sans-serif', 
   };
 


   const recipientEmail = "universshonen7@gmail.com";


  return (
    <div  className=' relative bg-gradient-to-tr from-black via-gray-500 to-black animate-gradient p-6 h-[350vh] max-lg:h-[400vh] max-xl:h-[400vh] max-md:h-[400vh]  max-sm:h-[550vh] max-xs:h-[700vh] w-[100%]  overflow-x-hidden overflow-y-hidden max-sm:overflow-x-hidden  max-xs:overflow-x-hidden  landscape '     >
    <SEO />

   <main  className="relative top-40 h-[400vh]   max-xs:h-[550vh]     max-sm:h-[550vh]  space-y-24 
     max-md:h-[350vh]
  
   max-lg:h-[350vh]
max-xl:h-[350vh]



   ">


       {/* header */}
    <header data-aos="fade-down" className="min-h-[calc(50px+env(safe-area-inset-top))] fixed top-0 left-0 right-0 w-full shadow-2xl custom-shadow bg-black bg-opacity-50 backdrop-blur-md p-6 
             text-center z-50 h-[10%] max-sm:fixed max-sm:top-0 max-sm:left-0 max-sm:right-0 
             flex items-center justify-center   pt-safe-top "
       >

         <img src={logo1}  alt='shonen sama logo streaminge animer '  className='relative animate-pulse  top-[10px] item-center w-[250px]'/>



        
      </header>
   

       {/* première section */}

   <div className="flex flex-col md:flex-row   space-y-32">
      <div  className="   h-[100%]     w-full md:w-1/2    ">
      <Boxe className=" space-y-32 ">

        
         <img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
            max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
            max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
             max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
            max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

          " src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZWRvY2xiMTB3bGVyZmRsY3FlZ3E0dmFram5pZnFpaW8wZjNsMnNhcCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/KGd6ns7MR1gPCRT52z/giphy.gif" alt="one piece , bloc streaming de one piece appuie sur play "/>

          <h1 className='absolute top-10  left-[60%]  text-7xl
           max-sm:top-[60%]  max-sm:left-[32%]  max-sm:text-4xl
            max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-4xl
              max-md:top-10   max-md:left-[60%]   max-md:text-6xl
                max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
          ' style={style}  >one piece</h1>   
          <div  className='absolute top-[60%]  left-[46%] 
         
           max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>
            
       
         <Button    onClick={() => setIsModalOpen(true)} ></Button>
        
         </div>

     </Boxe> 
     
      </div>


       {/* premiere section contenant un chatte boxe */}

      <div className="  h-96    w-full md:w-1/2  relative top-[-125px] 
      
      max-sm:top-[20%]
       max-sm:left-[0%]  
      max-lg:left-[2%]   
      max-lg:top-[-130px]
        max-xl:top-[-130px]
       max-xl:left-[2%]
        max-md:left-[2%]
        max-md:top-[50%]
     
      
      
      
      ">
      <Boxe  >         
      <img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
            max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
            max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
             max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
            max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

          " src="  https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExaDlpMTRkb2lyYmphNHFiNmJxaGtuMXEwaWt2YXN2ZWM3bnVucDN6YiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Fh8uUS3EE9viM/giphy.gif  " alt="dragone daima les nouveaux aventure de son goku et c'est compagnant "/>

          <h2 className='absolute top-10  left-[60%]  text-6xl
             max-sm:top-[60%]  max-sm:left-[31%]  max-sm:text-4xl
            max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-5xl
              max-md:top-10   max-md:left-[60%]   max-md:text-6xl
                max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
          ' style={style}  >DBZ DAIMA</h2>   

<div  className='absolute top-[60%]  left-[46%] 
 
          max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>
         <Button  onClick={() => setIsModal1Open(true)}    ></Button>

         </div>
      </Boxe>


      </div>
  
   </div>



    {/* deuxieme section  */}

   <div    className="flex flex-col md:flex-row   space-y-32      max-md:space-y-32  "   >
      <div  className="  h-96    w-full md:w-1/2 ">
      <Boxe  data-aos="fade-left"   >

        
<img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
   max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
   max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
    max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
   max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

 " src=" https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExemQwcGl0dmkyNDM4Y2N1YzlpcHZsNDV5eXV4ajJ0dnQyM20xZnF5MiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/J6JazAkCVLId91L4yM/giphy.gif " alt="demon slayer decouvrer les aventure du chasseur de démon"/>

 <h3 className='absolute top-10  left-[60%]  text-6xl
    max-sm:top-[60%]  max-sm:left-[35%]  max-sm:text-4xl
   max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-5xl
     max-md:top-10   max-md:left-[60%]   max-md:text-6xl
       max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
 ' style={style}  >Demon s..</h3>  

<div  className='absolute top-[60%]  left-[46%]
  
           max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>
<Button onClick={() => setIsModal2Open(true)}  ></Button>
</div>

</Boxe>
      
      
      
      </div>


{/* deuxieme section  un chatte boxe */}

      <div   className="  h-96   w-full md:w-1/2  relative top-[-125px] 
      
     max-sm:top-[20%]
      max-sm:left-[0%] 
      max-lg:left-[2%]   
      max-lg:top-[-130px]
        max-xl:top-[-130px]
       max-xl:left-[2%]
        max-md:left-[2%]
        max-md:top-[50%]
      
      
      
         "    >
      
      <Boxe  >    

       
      <img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
   max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
   max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
    max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
   max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

 " src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExbmQ5YWw0cTZqcjUzY25yMWR0NDNlbTF6ZWt4ZHY5d295dGpnNjd5aCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/cacIWu2W3XT3yaiPzt/giphy.gif" alt="jujutsu kaisen  chasseurs de fléo qui gagnera "/>

 <h5 className='absolute top-10  left-[60%]  text-6xl
   max-sm:top-[60%]  max-sm:left-[35%]  max-sm:text-4xl
   max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-5xl
     max-md:top-10   max-md:left-[60%]   max-md:text-6xl
       max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
 ' style={style}  >jujutsu </h5>
  
  <div  className='absolute top-[60%]  left-[46%] 
 
           max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>
<Button  onClick={() => setIsModal3Open(true)} ></Button>
</div>







      
      </Boxe>

      
      
      </div>
  
   </div>






      
   <div className="flex flex-col md:flex-row     space-y-24 ">
      <div  className="h-96  w-full md:w-1/2 ">
      
      <Boxe>

        
<img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
   max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
   max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
    max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
   max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

 " src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExdzMwZDFrcHl6bGc4czFsMHhyNDB6eGUzbjA3ZHBxMW8yaDRjY2ZoMiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ySvhFxq6Z4LrbqaikJ/giphy.gif " alt="solo levling   devenire le plus fort aventurier des donjon"/>

 <h6 className='absolute top-10  left-[60%]  text-6xl
    max-sm:top-[60%]  max-sm:left-[35%]  max-sm:text-4xl
   max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-5xl
     max-md:top-10   max-md:left-[60%]   max-md:text-6xl
       max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
 ' style={style}  >solo lev..</h6> 

 <div className='absolute top-[60%]  left-[46%]
  
           max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>

<Button   onClick={() => setIsModal4Open(true)}     ></Button>
</div>
</Boxe>
      



      
      </div>
      <div  className="  h-96   w-full md:w-1/2   relative top-[-100px] 
      
       max-sm:top-[20%]
        max-sm:left-[0%] 
      max-lg:left-[2%]   
      max-lg:top-[-100px]
        max-xl:top-[-99px]
       max-xl:left-[2%]
        max-md:left-[2%]
        max-md:top-[50%]
     
      
      
      
      
      
      "    >
      
      <Boxe  >    

       
<img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

" src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExaGxoMHJjdWdheWF1Y2lxeGRtdWRtbTRiZG1sMTVmOGowZG0yOHZzbyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/kNBLrv3JiDKw/giphy.gif" alt="deat not"/>

<h1 className='absolute top-10  left-[60%]  text-6xl
   max-sm:top-[60%]  max-sm:left-[30%]  max-sm:text-4xl
max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-5xl
max-md:top-10   max-md:left-[60%]   max-md:text-6xl
 max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
' style={style}  >DEATH NOTE </h1> 

<div className='absolute top-[60%]  left-[46%] 

           max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>

<Button   onClick={() => setIsModal5Open(true)}></Button>


</div>







</Boxe>


      
      
      
      
      
      </div>
  
   </div>  
  






       {/* première section */}
       <div className="flex flex-col md:flex-row   space-y-32">
      <div  className="   h-[100%]     w-full md:w-1/2    ">
      <Boxe className=" space-y-32 ">

        
         <img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
            max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
            max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
             max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
            max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

          " src="https://media1.tenor.com/m/X1cxtx7vaJ8AAAAd/thorfinn.gif  "    alt="one piece , bloc streaming de one piece appuie sur play "/>

          <h1 className='absolute top-10  left-[60%]  text-7xl
           max-sm:top-[60%]  max-sm:left-[32%]  max-sm:text-4xl
            max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-4xl
              max-md:top-10   max-md:left-[60%]   max-md:text-6xl
                max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
          ' style={style}  >vinland</h1>   
          <div  className='absolute top-[60%]  left-[46%] 
         
           max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>
         <Button   onClick={() => setIsModal6Open(true)} ></Button>
         </div>

     </Boxe> 
      </div>


       {/* premiere section contenant un chatte boxe */}

      <div className="  h-96    w-full md:w-1/2  relative top-[-125px] 
      
      max-sm:top-[20%]
       max-sm:left-[0%]  
      max-lg:left-[2%]   
      max-lg:top-[-130px]
        max-xl:top-[-130px]
       max-xl:left-[2%]
        max-md:left-[2%]
        max-md:top-[50%]
     
      
      
      
      ">
      <Boxe  >         
      <img className=" rounded-tl-[10px] rounded-bl-[10px]   max-sm:rounded-tr-[10px]        h-[100%]  w-[50%]
            max-sm:w-[100%] max-sm:h-[50.5%]  max-sm:rounded-bl-[0px] 
            max-lg:w-[100%] max-lg:h-[50.5%]  max-lg:rounded-bl-[0px]   max-lg:rounded-tr-[10px]
             max-xl:w-[100%] max-xl:h-[50.5%]  max-xl:rounded-bl-[0px] 
            max-md:rounded-tl-[10px]  max-md:rounded-bl-[10px]    max-xl:rounded-tr-[10px]        max-md:h-[100%]    max-md:w-[50%]

          " src=" https://media1.tenor.com/m/TjUe_-2BIfkAAAAC/hyakkimaru-dororo.gif" alt="dragone daima les nouveaux aventure de son goku et c'est compagnant "/>

          <h2 className='absolute top-10  left-[60%]  text-6xl
             max-sm:top-[60%]  max-sm:left-[31%]  max-sm:text-4xl
            max-lg:top-[60%]  max-lg:left-[28%]  max-lg:text-5xl
              max-md:top-10   max-md:left-[60%]   max-md:text-6xl
                max-xl:top-[60%]  max-xl:left-[35%]  max-xl:text-5xl
          ' style={style}  >Dororo</h2>   

<div  className='absolute top-[60%]  left-[46%] 
 
          max-sm:top-[80%]  max-sm:left-[22%]  
            max-lg:top-[80%]  max-lg:left-[22%]  
              max-md:top-[70%]   max-md:left-[46%]  
                max-xl:top-[80%]  max-xl:left-[28%]    
          
          
          '>
         <Button  onClick={() => setIsModal7Open(true)}    ></Button>

         </div>
      </Boxe>


      </div>
  
   </div>









   </main>

   <footer  className='w-full h-[20%] flex  fixed  z-50  left-[-0%]    top-[90%]     shadow-2xl custom-shadow bg-black bg-opacity-50 backdrop-blur-md   '>

<div className='w-[30%] h-[50%] relative flex space-x-20     max-sm:space-x-10    items-center left-[10%] '>
<div>
<a href={`mailto:${recipientEmail}`}>
<FontAwesomeIcon icon={faEnvelope} size="2x" color="#ffffff" />
</a>

</div>

<div>
<a href="https://www.tiktok.com/@shonensamaaa" target="_blank" rel="noopener noreferrer"  >
<FontAwesomeIcon icon={faTiktok} size="2x" color="#ffffff " />
</a>
</div>



</div>
<div className='w-[25%] h-[50%] relative  max-sm:w-[60%]   max-xs:left-[15%] max-sm:left-[10%] text-lime-50 align text-xs'>

<p className='relative top-[28%]  max-xs:top-[10%]  '>  "Plongez dans l'univers fascinant des animes et des mangas avec l'Équipe Shonen Universe, composée de véritables passionnés.  </p>
</div>

</footer> 
      
      
      
   
      {/* Afficher le modal si isModalOpen est vrai */}
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
{isModal1Open && <Modal1 onClose={() => setIsModal1Open(false)} />}
{isModal2Open && <Modal2 onClose={() => setIsModal2Open(false)} />}
{isModal3Open && <Modal3 onClose={() => setIsModal3Open(false)} />} 
{isModal4Open && <Modal4 onClose={() => setIsModal4Open(false)} />} 
{isModal5Open && <Modal5 onClose={() => setIsModal5Open(false)} />}  
{isModal6Open && <Modal6 onClose={() => setIsModal6Open(false)} />}   
{isModal7Open && <Modal7 onClose={() => setIsModal7Open(false)} />}     
  </div>
  );
}

export default Premierepage;